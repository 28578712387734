import React, { useState } from 'react'

// Components
import AtivacaoModal from 'src/components/UI/Forms/InterAtivacaoClientesForm'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

// Sections
import Header from '../../nao-consigo-acessar-a-minha-conta/sections/contaPraGente/_index'
import SentimosMuito from '../../nao-consigo-acessar-a-minha-conta/sections/sentimosMuito/_index'
import ContaDigitalCompleta from '../../nao-consigo-acessar-a-minha-conta/sections/contaDigitalCompleta/_index'
import BeneficiosContaMEI from '../../nao-consigo-acessar-a-minha-conta/sections/beneficiosContaMEI/_index'
import HelpCenter from '../../helpCenter/_index'

import pageContext from './pageContext.json'
import { Wrapper } from '../../style'

const OutroMotivoMEI = () => {
  const page = 'MEI'
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <AtivacaoModal closeModal={() => setOpenModal(false)} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountPJFormModal}
        <Header page={page} />
        <SentimosMuito page={page} />
        <ContaDigitalCompleta page={page} />
        <BeneficiosContaMEI />
        <HelpCenter bgColor='bg-white' />
      </Layout>
    </Wrapper>
  )
}

export default OutroMotivoMEI
