import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SolutionsTable = styled.div`
  @media ${device.desktopLG} {
    padding: 0 80px;
  }
  @media ${device.desktopXL} {
    padding: 0 10px;
  }
`

export const TableCell = styled.div`
  div {
    width: 33%;

    img { 
      width: 24px; 
    }
    @media ${device.tablet} {

      width: 60%;
      :first-child {
        width: 25%;
      }
      :last-child {
        width: 15%;
      }
    }

    p {
      padding: 10px 0;
    }
  } 

  .text-orange-table {
    color: ${orange.extra};
  }

  .border-orange-top {
    border-left: 1px solid ${orange.extra};
    border-right: 1px solid ${orange.extra};
    border-top: 1px solid ${orange.extra};
    padding: 15px 0 15px 0;
    border-radius: 8px 8px 0 0;
  }

  .border-orange {
    border-left: 1px solid ${orange.extra};
    border-right: 1px solid ${orange.extra};
    padding: 22px 0;
  }

  .border-orange-bottom {
    border-left: 1px solid ${orange.extra};
    border-right: 1px solid ${orange.extra};
    border-bottom: 1px solid ${orange.extra};
    padding: 29px 0 29px 0;
    border-radius: 0 0 8px 8px;
  }
`

export const HR = styled.hr`
  margin: 0;
`
