import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Logo from 'inter-frontend-svgs/lib/logos/banco-color'

import { SolutionsTable, TableCell, HR } from './style'

const BeneficiosContaMEI = () => {
  return (
    <section className='py-5 bg-grayscale--100'>
      <div className='container py-lg-4 py-xl-5'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-24 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-50 text-md-center text-grayscale--500 mb-3'>
              Soluções para simplificar a gestão do seu negócio
            </h2>
            <div className='col-lg-10 col-xl-8 mx-lg-auto px-0'>
              <p className='fs-18 lh-22 text-md-center text-grayscale--500'>
                A conta digital MEI do Inter tem soluções para simplificar o dia a dia da sua empresa.
              </p>
              <SolutionsTable className='mt-4 mt-md-5'>
                <TableCell className='d-flex justify-content-between'>
                  <div className='d-flex align-items-center'><h3 className='fs-16 lh-20 mb-0 fw-600 text-grayscale--500 py-3'>Serviços</h3></div>
                  <div className='d-flex align-items-center justify-content-center'>
                    <h3 className='fs-16 lh-20 mb-0 fw-600 text-grayscale--500 py-3 text-center'>
                      <span className='d-md-block'>Outros bancos</span> digitais
                    </h3>
                  </div>
                  <div className='d-flex justify-content-center align-items-center border-orange-top py-3'>
                    <Logo width={62} height={22} className='inter-logo' />
                  </div>
                </TableCell>
                <HR />
                <TableCell className='d-flex justify-content-between align-items-center'>
                  <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Sem tarifa de manutenção</p></div>
                  <div className='d-flex justify-content-center'><OrangeIcon size='MD' color='#161616' icon='exit' /></div>
                  <div className='d-flex justify-content-center border-orange'>
                    <OrangeIcon size='MD' color='#ff7a00' icon='check' />
                  </div>
                </TableCell>
                <HR />
                <TableCell className='d-flex justify-content-between align-items-center'>
                  <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Emissão de boletos</p></div>
                  <div className='d-flex justify-content-center'><OrangeIcon size='MD' color='#161616' icon='exit' /></div>
                  <div className='d-flex justify-content-center border-orange'>
                    <OrangeIcon size='MD' color='#ff7a00' icon='check' />
                  </div>
                </TableCell>
                <HR />
                <TableCell className='d-flex justify-content-between align-items-center'>
                  <div><p className='fs-14 lh-17 mb-0 text-grayscale--500'>Pix gratuito</p></div>
                  <div className='d-flex justify-content-center'>
                    <OrangeIcon size='MD' color='#161616' icon='exit' />
                  </div>
                  <div className='d-flex justify-content-center border-orange'>
                    <OrangeIcon size='MD' color='#ff7a00' icon='check' />
                  </div>
                </TableCell>
                <HR />
                <TableCell className='d-flex justify-content-between align-items-center'>
                  <div>
                    <p className='fs-14 lh-17 mb-0 text-grayscale--500 py-2 py-md-3'>Cartão de crédito sem anuidade <span className='d-block fs-10 fs-md-12'>*Sujeito a análise</span></p>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <OrangeIcon size='MD' color='#161616' icon='exit' />
                  </div>
                  <div className='d-flex justify-content-center border-orange-bottom'>
                    <OrangeIcon size='MD' color='#ff7a00' icon='check' />
                  </div>
                </TableCell>
              </SolutionsTable>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BeneficiosContaMEI
